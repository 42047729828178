import React from "react";
import Countdown from 'react-countdown';
import "./Main.css"
function Main() {
    const Completionist = () => <span>¡Vive la experiencia!</span>;

    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, completed }) => {
        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <div className='containerCuenta'>
                    <div className='cuadrado'>
                        <div className='numero'>{days}</div>
                        <div className='formato'>Días</div>
                    </div>
                    <div className='cuadrado'>
                        <div className='numero'>{hours}</div>
                        <div className='formato'>Horas</div>
                    </div>
                    <div className='cuadrado'>
                        <div className='numero'>{minutes}</div>
                        <div className='formato'>Minutos</div>
                    </div>

                </div>
            )
        }
    };


    const abrirFormulario = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSdAI2-7wcn5YSv-M0p9GakriTuSEsRJSCqswj1H8p0S_3-BAA/viewform", "_blank", "");
    }
    return (
        <>
            <div className="containerMain" id="containerMain">

                <div className="wrapperTitulo">
                    <p>Semana de la <br /> Innovación <br />Educativa</p>
                </div>

                <div className="wrapperContador">
                    <Countdown
                        date={"2024-07-16"}
                        renderer={renderer}
                    />
                    <button onClick={abrirFormulario}>¡Inscríbete!</button>
                </div>
            </div>
        </>
    )
}

export default Main;