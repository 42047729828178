import React from "react";
import "./Card.css"
import $ from "jquery";
const Carta = () => {


    /*Inteligencia Artificial*/
    $(document).ready(function () {
        $("#eduv").on("click", function () {
            $(".tallerescorrespondientes")
                .html('<ul>' +
                      '<li>IA a tu medida, ¿Cómo entrenar a tu IA para redacción?</li>' +
                      '<li>I nnov A ción en la educación superior.</li>' +
                      '</ul>');
        });
    });

    /*Habilidades Comunicativas*/
    $(document).ready(function () {
        $("#dea").on("click", function () {
            $(".tallerescorrespondientes")
                .html('<ul>'+
                '<li>El poder de comunicarnos.</li>' +
                '</ul>');
        });
    });

    /* Diseño en educación */
    $(document).ready(function () {
        $("#si").on("click", function () {
            $(".tallerescorrespondientes")
                .html('<ul>'+
                '<li>Diseño para no diseñadores.</li>'+
                '<li>Del lápiz al arte digital.</li>'+
                '<li>Identidad visual: del concepto a la realidad.</li>'+
                '</ul>');
        });
    });

  /*Metodologias activas*/   
    $(document).ready(function () {
        $("#pc").on("click", function () {
            $(".tallerescorrespondientes")
                .html('<ul>'+
                '<li>Ofimática de 0 a 100: Sorprende con Word.</li>'+
                '<li>Ofimática de 0 a 100: Sorprende con PowerPoint.</li>'+
                '<li>Ofimática de 0 a 100: Sorprende con Excel.</li>'+
                '<li>Procesamiento, análisis e interpretación de datos.</li>'+
                '</ul>');
        });
    });
/*--------------------------------------------------------------------------------*/
/*Tarjetas con sus respectivos talleres*/
/*-------------------------------------------------------------------------------*/

/*Inteligencia Artificial*/
    const Lista = [
        {
            nombre: " Inteligencia ",
            nombre2: "artificial",
            clase: "eduv"
        },

        /*Habilidades comunicativas*/
        {
            nombre: "Habilidades  ",
            nombre2: "comunicativas",

            clase: "dea"
        },

        /*Diseño en educación*/
        {
            nombre: "Diseño en",
            nombre2: "educación",

            clase: "si"
        },

        /*Analítica de datos*/
        {
            nombre: "Analítica de",
            nombre2: "datos",

            clase: "pc"
        },
    ]

    return (
        <>
            <h1 className="tituloCartas">Categorías</h1>

            <div className="containerCartas" id="containerCartas">
                <div className="cartasDesc">
                    <div className="tallerescorrespondientes">

                    </div>
                </div>
                <div className="cartasCard">
                    {
                        Lista.map((item) => (
                            <div id={item.clase}>
                                <h3>{item.nombre} <br /> {item.nombre2}</h3>

                                <button >Talleres</button>
                            </div>
                        ))}
                </div>
            </div>
        </>
    )
}

export default Carta;