import React, { useEffect, useRef } from "react";
import "./Header.css"
import logo2 from "./Logo2.png"

import $ from "jquery";
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { AiOutlineMenu } from 'react-icons/ai';


function Header() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const Barrita = useRef()

    useEffect(() => {
        $(document).on("scroll", function () {
            let p = $(Barrita.current).last();
            let offset = p.offset();
            if (offset.top === 0) {
                $(".navBar").removeClass('barra');

            } else {
                $(".navBar").addClass('barra');

            }
        });

    }, [])

    const Inicio = () => {
        let tiempo = tiempo || 0;
        $("html, body").animate({ scrollTop: $("#containerMain").offset().top }, tiempo);
        handleClose();
    }


    const Categorías = () => {
        let tiempo = tiempo || 0;
        $("html, body").animate({ scrollTop: $("#containerCartas").offset().top }, tiempo);
        handleClose();

    }

    const Agenda = () => {
        let tiempo = tiempo || 0;
        $("html, body").animate({ scrollTop: $("#calendarContainer").offset().top }, tiempo);
        handleClose();
    }

    const Talleristas = () => {
        let tiempo = tiempo || 0;
        $("html, body").animate({ scrollTop: $("#slider").offset().top }, tiempo);
        handleClose();
    }

    const Experiencias = () => {
        let tiempo = tiempo || 0;
        $("html, body").animate({ scrollTop: $("#experienciaContainer").offset().top }, tiempo);
        handleClose();
    }

    const Videos = () => {
        let tiempo = tiempo || 0;
        $("html, body").animate({ scrollTop: $("#videosContainer").offset().top }, tiempo);
        handleClose();
    }

    const Contacto = () => {
        let tiempo = tiempo || 0;
        $("html, body").animate({ scrollTop: $("#atencionPrincipal").offset().top }, tiempo);
        handleClose();
    }

    return (
        <>
            <div className="navBar" ref={Barrita}>
                <div className="imagen">
                    <img src={logo2}></img>

                </div>
                <div className="items">
                    <a onClick={Inicio}>Inicio</a>
                    <a onClick={Categorías}>Categorías</a>
                    <a onClick={Agenda}>Agenda</a>
                    <a onClick={Talleristas}>Talleristas</a>
                    <a onClick={Experiencias}>Experiencias</a>
                    <a onClick={Videos}>Videos</a>
                    <a onClick={Contacto}>Contacto</a>

                </div>
                <AiOutlineMenu className="botonheader" letiant="primary" onClick={handleShow}>
                    Launch
                </AiOutlineMenu>

                <Offcanvas show={show} scroll={true} onHide={handleClose}>

                    <Offcanvas.Body>
                        <div className="canvasContainer">
                            <a onClick={Inicio}>Inicio</a>
                            <a onClick={Categorías}>Categorías</a>
                            <a onClick={Agenda}>Agenda</a>
                            <a onClick={Talleristas}>Talleristas</a>
                            <a onClick={Experiencias}>Experiencias</a>
                            <a onClick={Videos}>Videos</a>
                            <a onClick={Contacto}>Contacto</a>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    )
}

export default Header;