import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import events from "./Eventos/Eventos";
import eventspro from "./Eventos/EventosProfesores";
import eventsAdm from "./Eventos/EventosAdm";
import eventsEst from "./Eventos/EventosEstudiantes";
import $ from 'jquery';
import "./Calendario.css"
const Calendario = () => {
    const [Fecha] = useState(`2024-07-16`)
    /*const [Fecha, setFecha] = useState(`${añoactual}-01-16`)*/
    const Calendario = useRef()
    const [Evento, setEvento] = useState(events)

    $(document).ready(function () {
        $(".fc-event").attr("target", "_blank").on("click", function (e) {
            e.preventDefault(); // Evita que el enlace se abra inmediatamente
            var width = 800; // Ancho deseado en píxeles
            var height = 600; // Alto deseado en píxeles
            var url = $(this).attr("href"); // Obtiene la URL del enlace
            // Abre una nueva ventana o pestaña con las dimensiones especificadas
            window.open(url, "_blank", "width=" + width + ", height=" + height);
        });
    })

    function Todos() {
        setEvento(events)
        $(".calendarBotones #adm").removeClass("seleccionado")
        $(".calendarBotones #estudiantes").removeClass("seleccionado")
        $(".calendarBotones #docentes").removeClass("seleccionado")
        $(".calendarBotones #todos").addClass("seleccionado")

    }

    function Doc() {
        setEvento(eventspro)
        $(".calendarBotones #adm").removeClass("seleccionado")
        $(".calendarBotones #estudiantes").removeClass("seleccionado")
        $(".calendarBotones #docentes").addClass("seleccionado")
        $(".calendarBotones #todos").removeClass("seleccionado")
    }

    function Adm() {
        setEvento(eventsAdm)
        $(".calendarBotones #adm").addClass("seleccionado")
        $(".calendarBotones #estudiantes").removeClass("seleccionado")
        $(".calendarBotones #docentes").removeClass("seleccionado")
        $(".calendarBotones #todos").removeClass("seleccionado")
    }

    function Est() {
        setEvento(eventsEst)
        $(".calendarBotones #adm").removeClass("seleccionado")
        $(".calendarBotones #estudiantes").addClass("seleccionado")
        $(".calendarBotones #docentes").removeClass("seleccionado")
        $(".calendarBotones #todos").removeClass("seleccionado")
    }

    // Función para formatear la hora en formato 12 horas
    const formatHour = (date) => {
        return new Intl.DateTimeFormat('es-CO', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        }).format(date);
    };

    const eventDidMount = (info) => {
        const startTime = formatHour(info.event.start);
        const endTime = formatHour(info.event.end);
        const eventTime = startTime + ' - ' + endTime;
        const timeElement = document.createElement('div');
        timeElement.classList.add('event-time');
        timeElement.textContent = eventTime;
        info.el.appendChild(timeElement);
    };

    useEffect(() => {
        if (Calendario.current) {
          const calendarApi = Calendario.current.getApi();
          calendarApi.setOption('eventTimeFormat', {
            hour: 'numeric',
            minute: '2-digit',
            meridiem: true, // Añadir AM/PM
          });
        }
    }, []);


    useEffect(() => {
        $(".calendarBotones #todos").addClass("seleccionado")
    }, [])


    return (
        <>
            <div className="calendarContainer" id="calendarContainer">
                <div className="calendarCalendario">
                    <div className="tituloCartas">
                        <h1>Agenda</h1>
                    </div>
                    <div className="calendarBotones">
                        <button id="todos" onClick={Todos}>Todos</button>
                        <button id="docentes" onClick={Doc}>Docentes</button>
                        <button id="adm" onClick={Adm}>Administrativos</button>
                        <button id="estudiantes" onClick={Est}>Estudiantes</button>

                    </div>
                    <div className="calendarCalendarios">

                        <FullCalendar
                            defaultView="dayGridMonth"
                            locale={'es'}
                            weekends={false}
                            initialDate={Fecha}
                            header={{
                                left: "prev,next",
                                center: "title",
                                right: "dayGridMonth,timeGridWeek,timeGridDay",
                                locale: 'es',

                            }}
                            eventDidMount={eventDidMount}
                            themeSystem="Simplex"
                            plugins={[dayGridPlugin]}
                            events={Evento}
                            ref={Calendario}
                            eventTimeFormat={{
                                hour: 'numeric',
                                minute: '2-digit',
                                hour12: false, // Utiliza el formato de 24 horas
                            }}
                        />
                    </div>

                </div>
            </div>
        </>
    )
}

export default Calendario;