import React, { useEffect } from "react";
import "./Atencion.css";
import { SiWhatsapp } from "react-icons/si";
import { AiOutlineMail } from "react-icons/ai";
import paula from "./Paula_SEMIE.png"
import $ from "jquery";
function Atencion() {

    useEffect(() => {


        const Contenedor3 = document.getElementById("atencionPrincipal")

        const AnimacionSlider = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $(".imagenPaula img").addClass("activePosition")
                    $(".imagenPaula p").addClass("activePositionp")
                } else {
                    $(".imagenPaula img").removeClass("activePosition");
                    $(".imagenPaula p").removeClass("activePositionp")
                }
            });
        }





        const observador4 = new IntersectionObserver(AnimacionSlider, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });


        observador4.observe(Contenedor3);

    }, [])

    return (
        <>
            <div className="atencionPrincipal" id="atencionPrincipal">
                <div className="atencionContainer">
                    <div className="atencionTitulo">
                        <h1>Atención al usuario</h1>
                        <p>Lunes a viernes 8:00 am a 6:00 pm <br /> Sábados de 8:00 am a 12:00 m</p>
                    </div>
                    <div className="atencionItems">
                        <div className="atencionItem1">
                            <SiWhatsapp />
                            <p>WhatsApp</p>
                            <p>+57 316 862 34 14</p>
                        </div>
                        <div className="atencionItem2">
                            <AiOutlineMail />
                            <p>Correo</p>

                            <p>cie@ucp.edu.co</p>
                        </div>
                    </div>

                </div>
                <div className="imagenPaula">
                    <img src={paula} alt="Paula" />
                </div>
                <footer>
                    <p>2023 Derechos Reservados Universidad Católica de Pereira - Centro de Innovación Educativa</p>
                </footer>
            </div>


        </>
    )
}

export default Atencion;