import React from "react";
import "./Sistema.css"
import descargar from "./Sistematizacion de Experiencias .docx"
const Sistema = () => {


    const abrirSistema = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSfmMdc_lPHP3ZFyBItoT2O7BPzE3WJKUXvr8DOnp4zTPpZhhg/closedform", "_blank", "");
    }

    return (
        <>
            <div className="sistemaContainer">
                <div className="sistemaItem">
                    <div className="sistemaContenido">
                        <h1>Sistematización de experiencias innovadoras</h1>
                        <p>¿Eres docente de la Universidad Católica de Pereira y tienes una experiencia innovadora de tu práctica educativa? Participa de la convocatoria en el marco de la Semana de Innovación Educativa. Te compartimos el formato de sistematización de experiencias pedagógicas, puedes diligenciarlo según los ítems y la rúbrica</p>
                        <p style={{ marginTop: "20px" }}>¿Qué esperas para compartir tus experiencias?</p>
                    </div>
                    <div className="sistemaBotones">
                        <button><a href={descargar} download>Descargar Formato</a>
                        </button>
                        <button onClick={abrirSistema}>Subir experiencia</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sistema;