const eventsEst = [

  /*16 DE JULIO AM */
  {
    title: "IA a tu medida, ¿Cómo entrenar a tu IA para redacción?",
    start: "2024-07-16T09:00:00",
    end: "2024-07-16T11:00:00",
    color: "orange",
    url: "http://www.google.com/calendar/event?action=TEMPLATE&dates=20240716T140000Z%2F20240716T160000Z&text=IA%20a%20tu%20medida%2C%20%C2%BFC%C3%B3mo%20entrenar%20a%20tu%20IA%20para%20redacci%C3%B3n%3F&location=CIE&details=IA%20a%20tu%20medida%2C%20%C2%BFC%C3%B3mo%20entrenar%20a%20tu%20IA%20para%20redacci%C3%B3n%3F"
  },

  /*16 DE JULIO PM */
  {
    title: "Ofimática de 0 a 100: Sorprende con Word.",
    start: "2024-07-16T14:00:00", // Cambiado a formato 24 horas
    end: "2024-07-16T16:00:00",   // Cambiado a formato 24 horas
    color: "orange",
    url: "http://www.google.com/calendar/event?action=TEMPLATE&dates=20240716T190000Z%2F20240716T210000Z&text=Ofim%C3%A1tica%20de%200%20a%20100%3A%20Sorprende%20con%20Word.&location=&details=Ofim%C3%A1tica%20de%200%20a%20100%3A%20Sorprende%20con%20Word."
  },

  /*17 DE JULIO AM*/
  {
    title: "Del lápiz al arte digital.",
    start: "2024-07-17T08:00:00",
    end: "2024-07-17T10:00:00",
    color: "orange",
    url: "http://www.google.com/calendar/event?action=TEMPLATE&dates=20240717T130000Z%2F20240717T150000Z&text=Del%20l%C3%A1piz%20al%20arte%20digital.&location=&details=Del%20l%C3%A1piz%20al%20arte%20digital."
  },

  /*17 DE JULIO AM */
  {
    title: "El poder de comunicarnos.",
    start: "2024-07-17T10:00:00",
    end: "2024-07-17T12:00:00",
    color: "orange",
    url: "http://www.google.com/calendar/event?action=TEMPLATE&dates=20240717T150000Z%2F20240717T170000Z&text=El%20poder%20de%20comunicarnos.&location=&details=El%20poder%20de%20comunicarnos."
  },

  /*17 DE JULIO PM*/
  {
    title: "Ofimática de 0 a 100: Sorprende con PowerPoint.",
    start: "2024-07-17T14:00:00", // Cambiado a formato 24 horas
    end: "2024-07-17T16:00:00",   // Cambiado a formato 24 horas
    color: "orange",
    url: "http://www.google.com/calendar/event?action=TEMPLATE&dates=20240717T190000Z%2F20240717T210000Z&text=Ofim%C3%A1tica%20de%200%20a%20100%3A%20Sorprende%20con%20PowerPoint.&location=&details=Ofim%C3%A1tica%20de%200%20a%20100%3A%20Sorprende%20con%20PowerPoint."
  },

  /*18 DE JULIO AM*/
  {
    title: "Identidad visual: del concepto a la realidad.",
    start: "2024-07-18T09:00:00",
    end: "2024-07-18T11:00:00",
    color: "orange",
    url: "http://www.google.com/calendar/event?action=TEMPLATE&dates=20240718T140000Z%2F20240718T160000Z&text=Identidad%20visual%3A%20del%20concepto%20a%20la%20realidad.&location=&details=Identidad%20visual%3A%20del%20concepto%20a%20la%20realidad."
  },

  /*18 DE JULIO PM*/
  {
    title: "Ofimática de 0 a 100: Sorprende con Excel.",
    start: "2024-07-18T14:00:00", // Cambiado a formato 24 horas
    end: "2024-07-18T16:00:00",   // Cambiado a formato 24 horas
    color: "orange",
    url: "http://www.google.com/calendar/event?action=TEMPLATE&dates=20240718T190000Z%2F20240718T210000Z&text=Ofim%C3%A1tica%20de%200%20a%20100%3A%20Sorprende%20con%20Excel.&location=&details=Ofim%C3%A1tica%20de%200%20a%20100%3A%20Sorprende%20con%20Excel."
  },

  /*19 DE JULIO AM*/
  {
    title: "Manual para creators: una cuestión de autoría.",
    start: "2024-07-19T09:00:00",
    end: "2024-07-19T11:00:00",
    color: "orange",
    url: "http://www.google.com/calendar/event?action=TEMPLATE&dates=20240719T140000Z%2F20240719T160000Z&text=Manual%20para%20creators%3A%20una%20cuesti%C3%B3n%20de%20autor%C3%ADa.&location=&details=Manual%20para%20creators%3A%20una%20cuesti%C3%B3n%20de%20autor%C3%ADa."
  },


  /*19 DE JULIO PM*/
  {
    title: "Procesamiento, análisis e interpretación de datos.",
    start: "2024-07-19T14:00:00", // Cambiado a formato 24 horas
    end: "2024-07-19T17:00:00",   // Cambiado a formato 24 horas
    color: "orange",
    url: "http://www.google.com/calendar/event?action=TEMPLATE&dates=20240719T190000Z%2F20240719T220000Z&text=Procesamiento%2C%20an%C3%A1lisis%20e%20interpretaci%C3%B3n%20de%20datos.&location=&details=Procesamiento%2C%20an%C3%A1lisis%20e%20interpretaci%C3%B3n%20de%20datos."
  },

];


export default eventsEst;