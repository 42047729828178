import React, { useEffect } from "react";
import imagen1  from "./Fotos/FOTO-LUIS-FERNANDO-BOTERO.png";
import imagen2 from "./Fotos/FOTO-HEILLER-ABADIA.png";
import imagen3 from "./Fotos/FOTO-ANDREA.png";
import imagen4 from "./Fotos/FOTO-VALENTINA-GUERRERO.png";
import imagen5 from "./Fotos/FOTO-CRISTIAN-RUIZ.png";
import imagen6 from "./Fotos/FOTO-DIANA-LOPEZ.png";
import imagen7 from "./Fotos/FOTO-ALEJANDRA-MARIN.png";

import $ from "jquery";
import "./Slider.css"

const Slider = () => {

    const items = [

        {
            foto: imagen1,
            desc: "Docente en formación constante que gusta de compartir sus conocimientos con las personas que pueda porque es un creyente que la educación en Latinoamérica puede mejorar. Es un facilitdor en procesos de enseñanza y aprendizaje y tiene habilidad para la comunicación que demuestra como creador de la comunidad Internacional de Profesores Cerebrote, con más de 15 años de experiencia en docencia.",
            id: "1",
            contenedor: "contenedor1",
            fotito: "fotito1",
            active: "foto1",
            animacion: "desc1",
            persona: "Luis Fernando Botero"
        },
        {
            foto: imagen2,
            desc: "Comunicador Social-Periodista, Doctor en Comunicación Estratégica y Magister en Comunicación Organizacional. Profesor de planta de la Universidad Católica de Pereira. Con más de 20 años de experiencia en gestión de marcas, auditoría de comunicación organizacional y comunicación educativa.",
            id: "2",
            contenedor: "contenedor2",
            fotito: "fotito2",
            active: "foto2",
            animacion: "desc2",
            persona: "Heiller Abadía"
        },
        {
            foto: imagen3,
            desc: "Licenciada en Comunicación e Informática Educativa y Comunicadora Social, estudiante de maestría en Comunicación Transmedia. Actualmente, es asesora pedagógica del Centro de Innovación Educativa de la Universidad Católica de Pereira. Cuenta con experiencia en el trabajo con Instituciones de Educación Superior en construcción de ambientes virtuales de aprendizaje, formación a comunidad sobre competencias TIC y habilidades digitales, también ha trabajado en procesos comunitarios y ha desarrollado proyectos vinculados al ámbito educativo, de comunicación e investigación.",
            id: "3",
            contenedor: "contenedor3",
            fotito: "fotito3",
            active: "foto3",
            animacion: "desc3",
            persona: "Andrea Carolina Londoño"
        },
        {
            foto: imagen4,
            desc: "Comunicadora Social - Periodista, Especialista en gerencia de comunicación corporativa, con estudios en liderazgo, planeación y ejecución estratégica, gestión del cambio, propiedad intelectual y derechos de autor. Cuenta con más de 6 años de experiencia en empresas privadas en áreas como comunicación, mercadeo, desarrollo y capacitación. Actualmente es asesora en calidad de contenidos y apoya a la comunidad educativa en la calidad y ética que requieren los procesos de enseñanza.",
            id: "4",
            contenedor: "contenedor4",
            fotito: "fotito4",
            active: "foto4",
            animacion: "desc4",
            persona: "Valentina Guerrero"
        },
        {
            foto: imagen5,
            desc: "Diseñador Industrial graduado de la Universidad Católica de Pereira, se desempeña como Diseñador Audiovisual de los diversos cursos virtuales brindados por la Universidad: Maestrías, 	Diplomados, Especializaciones y cursos cortos. Dentro de sus funciones está realizar la línea estética de los cursos y todos los contenidos audiovisuales de cada uno. Brindar asesoría y capacitaciones en diseño y las herramientas especializadas a toda la comunidad universitaria, estudiantes, docentes y 	administrativos.",
            id: "5",
            contenedor: "contenedor5",
            fotito: "fotito5",
            active: "foto5",
            animacion: "desc5",
            persona: "Cristian Daniel Ruiz"
        },
        {
            foto: imagen6,
            desc: 'Diseñadora industrial de la Universidad Católica de Pereira, apasionada por la innovación y 	la creatividad. Con experiencia en la producción de contenidos de realidades extendidas para la educación, diseño de mockups de aplicativos y páginas web, creación de ilustraciones digitales y modelado 3D. Actualmente, diseñadora de experiencias de usuario del Centro de Innovación Educativa.',
            id: "6",
            contenedor: "contenedor6",
            fotito: "fotito6",
            active: "foto6",
            animacion: "desc6",
            persona: "Diana Puentes López"
        },
        {
            foto: imagen7,
            desc: "Con formación en diseño gráfico y producción audiovisual, se desempeña como diseñadora 	multiplataforma en la Universidad Católica de Pereira. Ha tenido experiencia en el manejo de redes 	sociales y diseño de piezas gráficas y audiovisuales, además ha realizado estudios en marketing y comunicación digital.",
            id: "7",
            contenedor: "contenedor7",
            fotito: "fotito7",
            active: "foto7",
            animacion: "desc7",
            persona: "Alejandra Marín Álvarez"
        },

    ];

    useEffect(() => {

        const Contenedor1 = document.getElementById("fotito1")
        const Contenedor2 = document.getElementById("fotito2")
        const Contenedor3 = document.getElementById("fotito3")
        const Contenedor4 = document.getElementById("fotito4")
        const Contenedor5 = document.getElementById("fotito5")
        const Contenedor6 = document.getElementById("fotito6")
        const Contenedor7 = document.getElementById("fotito7")

        const AnimacionSlider1 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto1").addClass("active");
                    $("#desc1").addClass("activo");
                    $("#desc11").addClass("activo");


                } else {
                    $("#foto1").removeClass("active");
                    $("#desc1").removeClass("activo");
                    $("#desc11").removeClass("activo");

                }
            });
        }

        const AnimacionSlider2 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto2").addClass("active");
                    $("#desc2").addClass("activo");
                    $("#desc21").addClass("activo");


                } else {
                    $("#foto2").removeClass("active");
                    $("#desc2").removeClass("activo");
                    $("#desc21").removeClass("activo");

                }
            });
        }

        const AnimacionSlider3 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto3").addClass("active");
                    $("#desc3").addClass("activo");
                    $("#desc31").addClass("activo");


                } else {
                    $("#foto3").removeClass("active");
                    $("#desc3").removeClass("activo");
                    $("#desc31").removeClass("activo");

                }
            });
        }

        const AnimacionSlider4 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto4").addClass("active");
                    $("#desc4").addClass("activo");
                    $("#desc41").addClass("activo");


                } else {
                    $("#foto4").removeClass("active");
                    $("#desc4").removeClass("activo");
                    $("#desc41").removeClass("activo");

                }
            });
        }

        const AnimacionSlider5 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto5").addClass("active");
                    $("#desc5").addClass("activo");
                    $("#desc51").addClass("activo");


                } else {
                    $("#foto5").removeClass("active");
                    $("#desc5").removeClass("activo");
                    $("#desc51").removeClass("activo");

                }
            });
        }

        const AnimacionSlider6 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto6").addClass("active");
                    $("#desc6").addClass("activo");
                    $("#desc61").addClass("activo");


                } else {
                    $("#foto6").removeClass("active");
                    $("#desc6").removeClass("activo");
                    $("#desc61").removeClass("activo");

                }
            });
        }

        const AnimacionSlider7 = (entradas, observador) => {
            entradas.forEach(element => {
                if (element.isIntersecting) {
                    $("#foto7").addClass("active");
                    $("#desc7").addClass("activo");
                    $("#desc71").addClass("activo");


                } else {
                    $("#foto7").removeClass("active");
                    $("#desc7").removeClass("activo");
                    $("#desc71").removeClass("activo");

                }
            });
        }

        const observador1 = new IntersectionObserver(AnimacionSlider1, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });

        const observador2 = new IntersectionObserver(AnimacionSlider2, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });

        const observador3 = new IntersectionObserver(AnimacionSlider3, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });

        const observador4 = new IntersectionObserver(AnimacionSlider4, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });

        const observador5 = new IntersectionObserver(AnimacionSlider5, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });

        const observador6 = new IntersectionObserver(AnimacionSlider6, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });
        const observador7 = new IntersectionObserver(AnimacionSlider7, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        });

        observador1.observe(Contenedor1);
        observador2.observe(Contenedor2);
        observador3.observe(Contenedor3);
        observador4.observe(Contenedor4);
        observador5.observe(Contenedor5);
        observador6.observe(Contenedor6);
        observador7.observe(Contenedor7);
    }, [])



    function Atras(e) {

        if (e.target.id === "0") {
            $(".sliderContainer").css("order", "")
            $(`#contenedor${items.length}`).css("order", "-1")
            console.log(items.length)
        } else {
            $(".sliderContainer").css("order", "")
            $(`#contenedor${e.target.id}`).css("order", "-1")
        }
    }

    const Siguiente = (e) => {
        $(".sliderContainer").css("order", "")
        $(`#contenedor${e.target.id}`).css("order", "-1")
    }

    const sig = ">"
    const pre = "<"

    return (
        <>
            <div style={{ position: "relative" }}>

                <div className="slider" id="slider">
                    {
                        items.map((user) => (

                            <div className="sliderContainer" id={user.contenedor}>
                                <div className="containerFoto" id={user.fotito}>
                                    <div style={{ width: "100%", height: "calc(100%)", display: "flex", justifyContent: "center" }}>
                                        <img src={user.foto} alt="foto" id={user.active}></img>
                                    </div>

                                </div>
                                <div className="containerDescripcion">
                                    <h1 id={user.animacion + 1}> {user.persona}</h1>
                                    <p id={user.animacion}>{user.desc}</p>
                                    <div>
                                        <button onClick={Atras} id={Number(user.id) - Number(1)}>{pre}</button>
                                        <button onClick={Siguiente} id={Number(user.id) + Number(1)}>{sig}</button>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </div>
                <div className="Borde2">
                    <div className="Borde">
                        <div className="tituloTalleristas">
                            <h1>Talleristas</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Slider;